import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  ComponentLoader,
  ContentContainer,
  FormGroup,
  Heading,
  Row,
} from '@snsw/react-component-library';
import { Breadcrumbs, ErrorSummary, FileUpload } from 'ams-common';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import { QueryKey } from 'src/api/constants';
import { useClients, useMatterClients } from 'src/components/clients/hooks';
import DocumentModal from 'src/components/documentModal';
import { DocumentTableWithAccordion } from 'src/components/documentTableWithAccordion';
import { useMatterDocuments } from 'src/components/documentTableWithAccordion/hooks';
import { MatterDocuments } from 'src/components/documentTableWithAccordion/types';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { InvalidFormatUploadFailure } from 'src/components/fileUploadErrors';
import { ScreenNames } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';
import {
  useCorrespondenceDetails,
  useCreateUpdateCorrespondence,
  useRecipients,
  useUserContext,
} from 'src/hooks';
import { getSelectedRecipientData } from 'src/hooks/recipients/helpers';
import { CorrespondenceRequestBody, RecipientsData } from 'src/hooks/types';
import { PATHS } from 'src/routes/constants';
import { getMattersBreadcrumbs } from 'src/screens/common/matters/utils';
import { UserLoginType } from 'src/types';
import { HandleEditDocument, OperationType } from 'src/types/documentTypes';
import getContent from 'src/utils/contentUtils';
import { getSelectedMatterDocuments } from 'src/utils/documentHelper';
import { getValidInvalidFilesArray } from 'src/utils/fileUtils';
import * as Yup from 'yup';

import { MessageType } from '../../constants';

import { ConfirmModal } from './modals/confirmModal';
import { DraftModal } from './modals/draftModal';
import { DUE_DATE_EXTENSION_LIMIT_ACD } from './constants';
import { DetailsFrom } from './detailsForm';
import { getInitValuesFromResponse } from './helpers';
import { ButtonGroup, DocumentBody, StyledErrorContainer } from './styles';
import {
  RequestType,
  SubmitAuditCommencementDocumentsInitValues,
} from './types';
import { getRecipientOptions } from './utils';

const submitAuditCommencementDocumentsSchema = Yup.object().shape({
  customer: Yup.string().required('Customer selection is required'),
  recipient: Yup.string().required('Recipient selection is required'),
  dueDate: Yup.string()
    .test('date format validation', 'Invalid due date', (val) => {
      if (!val) return true;
      return dayjs(val, 'YYYY-MM-DD', true).isValid();
    })
    .test('no past dates', "Date can't be in the past", (val) => {
      if (!val) return true;
      const parsedDate = dayjs(val, 'YYYY-MM-DD', true);
      const today = dayjs();
      return parsedDate.isValid() && parsedDate.toDate() >= today.toDate();
    })
    .test(
      'due date must not exceed the extension limit',
      ` Due date cannot exceed ${DUE_DATE_EXTENSION_LIMIT_ACD} days`,
      (dateToCheck) => {
        if (dateToCheck) {
          const limitDate = dayjs().add(DUE_DATE_EXTENSION_LIMIT_ACD, 'days');
          return (
            dayjs(dateToCheck).isBefore(limitDate, 'day') ||
            dayjs(dateToCheck).isSame(limitDate, 'day')
          );
        }
        return false;
      },
    )
    .required('Due date is required'),
  subject: Yup.string().required('Message subject is required'),
  message: Yup.string().required('Message is required'),
  selectedDocuments: Yup.array().min(
    1,
    'At least one document must be selected',
  ),
});

const initialValues: SubmitAuditCommencementDocumentsInitValues = {
  recipient: '',
  customer: '',
  dueDate: '',
  subject: 'Notice of Payroll Tax Investigation',
  message: getContent('matters.correspondence.message.text'),
  selectedDocuments: [],
};

export const SubmitAuditCommencementDocuments = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<File[] | null>();
  const [invalidFiles, setInvalidFiles] = useState<File[] | null>(null);
  const [openFilesUploadErrorModal, setOpenFilesUploadErrorModal] =
    useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDraftModal, setShowDraftModal] = useState(false);
  const [recipients, setRecipients] = useState<RecipientsData | null>(null);
  const [editDocumentDetails, setEditDocumentDetails] =
    useState<HandleEditDocument | null>(null);
  const [operationType, setOperationType] = useState<OperationType>(
    OperationType.Add,
  );
  const [recipientOptions, setRecipientOptions] = useState<
    { text: string; value: string }[]
  >([]);
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);

  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;

  const [auditorMatterDocuments, setAuditorMatterDocuments] = useState<
    MatterDocuments | undefined
  >();

  const errorSummaryRef = useRef<HTMLDivElement>(null);

  const { matterId = '', correspondenceId: matterCorrespondenceId = null } =
    useParams();

  const { matterUId, matterCorrespondenceThreadId: threadId } = useMatter();

  const {
    data: clientsData,
    isLoading: isClientsDataLoading,
    isError: isClientsDataError,
  } = useMatterClients(matterUId, isCustomer);

  const { clientOptions, isError: isClientsError } = useClients(
    matterUId,
    isCustomer,
  );

  const {
    data,
    isLoading,
    isError: isCorrespondenceDetailsError,
  } = useCorrespondenceDetails(matterUId, threadId, matterCorrespondenceId);
  const {
    data: matterDocumentsData,
    isLoading: isMatterDocumentsDataLoading,
    isError: isMatterDocumentsDataLoadingError,
  } = useMatterDocuments(matterUId);

  const navigateSuccess = (statusCode: number, saveAs?: string) => {
    if (statusCode === 201) {
      navigate(`${PATHS.matters}/${matterId}${PATHS.threads}`, {
        state: {
          message: saveAs
            ? 'Draft saved successfully'
            : 'Correspondence sent successfully',
          messageType: MessageType.Success,
        },
      });
    }
  };

  const navigateError = (data: {
    status: number;
    code: string;
    messages?: string[];
  }) => {
    if (data?.messages?.[0].includes('proper date')) {
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-DUE_DATE-POST` as ErrorKey,
        ]),
      ]);
    } else {
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-${QueryKey.MATTERS_CORRESPONDENCES}-POST` as ErrorKey,
        ]),
      ]);
    }
  };

  const {
    mutate: createUpdateAuditCommencement,
    isLoading: isCreateUpdateCorrespondenceLoading,
  } = useCreateUpdateCorrespondence(navigateSuccess, navigateError);

  const formik = useFormik({
    initialValues,
    validationSchema: submitAuditCommencementDocumentsSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      setShowConfirmModal(true);
    },
    isInitialValid:
      submitAuditCommencementDocumentsSchema.isValidSync(initialValues),
  });

  const {
    errors,
    submitCount,
    values,
    isValid,
    setValues,
    setSubmitting,
    isSubmitting,
  } = formik;

  const {
    data: recipientsData,
    isLoading: isRecipientDataLoading,
    isError: isRecipientDataError,
  } = useRecipients(matterUId, isCustomer);

  useEffect(() => {
    if (matterUId) {
      setRecipients(getSelectedRecipientData(values.customer, recipientsData));
    }
  }, [matterUId, recipientsData, values.customer, setRecipients]);

  useEffect(() => {
    if (isClientsDataLoading || !clientsData) {
      return;
    }

    const customers =
      clientsData.length > 1
        ? clientsData.map(({ id }) => `${id}`).join(',')
        : `${clientsData[0].id}`;

    setValues({
      ...initialValues,
      customer: customers,
    });
  }, [clientsData, isClientsDataLoading, setValues]);

  useEffect(() => {
    if (isLoading || !data) {
      return;
    }

    const initValuesFromResponse = getInitValuesFromResponse(data);
    setValues({
      ...initValuesFromResponse,
    });
  }, [isLoading, data, setValues]);

  useEffect(() => {
    if (!isValid && submitCount > 0) {
      errorSummaryRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isValid, submitCount]);

  useEffect(() => {
    if (errorKeys.length > 0) {
      errorSummaryRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errorKeys]);

  useEffect(() => {
    if (!matterDocumentsData) {
      return;
    }

    setAuditorMatterDocuments(
      matterDocumentsData.filter(
        ({ displayStatus }) => displayStatus !== 'RECEIVED',
      ),
    );
  }, [matterDocumentsData]);

  useEffect(() => {
    const options = getRecipientOptions(recipients);
    if (!options || options.length === 0) {
      return;
    }
    setRecipientOptions(options);
  }, [recipients]);

  const handleFileUpload = async (uploadedFiles: File[]) => {
    const { validFiles, invalidFiles } =
      getValidInvalidFilesArray(uploadedFiles);

    if (invalidFiles.length > 0) {
      setOpenFilesUploadErrorModal(true);
    } else if (invalidFiles.length === 0) {
      setOpen(true);
    }

    setInvalidFiles(invalidFiles);
    setFiles(validFiles);
  };

  const handleClose = () => {
    setOpen(false);
    setFiles(null);
    setEditDocumentDetails(null);
    setOperationType(OperationType.Add);
    setOpenFilesUploadErrorModal(false);
    setInvalidFiles(null);
  };

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    formik.submitForm();
  };

  const handleDocumentEdit = (args: HandleEditDocument) => {
    setEditDocumentDetails(args);
    setOperationType(OperationType.Edit);
    setOpen(true);
  };

  const onConfirm = async ({
    matterUId,
    payload,
    requestType = RequestType.Add,
    threadId,
    correspondenceId,
    saveAs,
    isCustomer,
    isReply,
  }: {
    matterUId: string;
    payload: CorrespondenceRequestBody;
    requestType?: RequestType;
    threadId?: string;
    correspondenceId?: string;
    saveAs?: 'draft';
    isCustomer?: boolean;
    isReply?: boolean;
  }) => {
    await createUpdateAuditCommencement([
      matterUId,
      payload,
      requestType,
      threadId,
      correspondenceId,
      saveAs,
      isCustomer,
      isReply,
    ]);
  };

  useEffect(() => {
    if (isMatterDocumentsDataLoadingError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-${QueryKey.MATTER_DOCUMENTS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isMatterDocumentsDataLoadingError]);

  useEffect(() => {
    if (isCorrespondenceDetailsError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-${QueryKey.CORRESPONDENCE_DETAILS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isCorrespondenceDetailsError]);

  useEffect(() => {
    if (isClientsDataError || isClientsError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-${QueryKey.CLIENTS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isClientsDataError, isClientsError]);

  useEffect(() => {
    if (isRecipientDataError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-${QueryKey.RECIPIENT_CONTACTS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isRecipientDataError]);

  return (
    <>
      <ComponentLoader
        active={
          isMatterDocumentsDataLoading ||
          isRecipientDataLoading ||
          isSubmitting ||
          isCreateUpdateCorrespondenceLoading
        }
        fullPage
      />

      <FormikProvider value={formik}>
        <ContentContainer>
          {/* {isMatterDocumentsDataLoadingError ? (
            <InPageAlert variant="error" title="Error." compact>
              <p>Unable to load documents. Please try again after sometime.</p>
            </InPageAlert>
          ) : null} */}

          <Breadcrumbs
            paths={getMattersBreadcrumbs(
              'threads',
              'threads.submitAuditCommencementDocuments',
              matterId,
            )}
          />

          <Row>
            <Col span={8}>
              <Heading level={2}>
                {getContent(
                  'matter.correspondence.submitAuditCommencementDocuments.heading',
                )}
              </Heading>
              <Heading level={3}>
                {getContent(
                  'matter.correspondence.submitAuditCommencementDocuments.first.sub.heading',
                )}
              </Heading>
            </Col>
          </Row>

          <StyledErrorContainer
            ref={errorSummaryRef}
            showError={errorKeys.length > 0 || (!isValid && submitCount > 0)}
          />

          <ErrorHandler keys={errorKeys} />
          <ErrorSummary errors={errors} />

          <form>
            <DetailsFrom recipientOptions={recipientOptions} />
            <Row>
              <Col span={12}>
                <Heading level={3}>
                  {getContent(
                    'matter.correspondence.submitAuditCommencementDocuments.second.sub.heading',
                  )}
                </Heading>
                <FormGroup
                  name="selectedDocuments"
                  errorMessage={errors.selectedDocuments}
                  hasError={submitCount > 0 && errors.selectedDocuments}
                >
                  <DocumentTableWithAccordion
                    handleDocumentEdit={handleDocumentEdit}
                    matterDocuments={auditorMatterDocuments}
                  />
                </FormGroup>
                <DocumentBody>
                  <FileUpload onFileUpload={handleFileUpload} showHeading />
                </DocumentBody>
              </Col>
            </Row>
            <ButtonGroup>
              <Button
                variant="secondary"
                onClick={() => {
                  setSubmitting(true);
                  setShowDraftModal(true);
                }}
                disabled={isSubmitting}
              >
                Save as draft
              </Button>
              <Button
                onClick={(e) => {
                  setSubmitting(true);
                  handleFormSubmit(e);
                }}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </ButtonGroup>
          </form>
          {showConfirmModal && (
            <ConfirmModal
              onClose={() => {
                setSubmitting(false);
                setShowConfirmModal(!showConfirmModal);
              }}
              auditCommencementValues={values}
              clientOptions={clientOptions}
              recipientOptions={recipientOptions}
              matterUId={matterUId}
              correspondenceId={matterCorrespondenceId}
              threadId={threadId}
              selectedMatterDocuments={getSelectedMatterDocuments(
                values.selectedDocuments,
                auditorMatterDocuments,
              )}
              onConfirm={onConfirm}
            />
          )}
          {showDraftModal && (
            <DraftModal
              onClose={() => {
                setSubmitting(false);
                setShowDraftModal(!showDraftModal);
              }}
              auditCommencementValues={values}
              matterUId={matterUId}
              correspondenceId={matterCorrespondenceId}
              threadId={threadId}
              onConfirm={onConfirm}
            />
          )}
          {open && (
            <DocumentModal
              matterUId={matterUId}
              close={handleClose}
              documents={files || null}
              clientOptions={clientOptions}
              matterId={matterId}
              documentsData={auditorMatterDocuments}
              operationType={operationType}
              editDocumentDetails={editDocumentDetails}
            />
          )}
          <InvalidFormatUploadFailure
            open={openFilesUploadErrorModal}
            close={() => setOpenFilesUploadErrorModal(false)}
            onContinue={() => {
              if (files && files?.length > 0) {
                setOpen(true);
              } else {
                setOpenFilesUploadErrorModal(false);
              }
            }}
            invalidFiles={invalidFiles || []}
            validFiles={files || []}
          />
        </ContentContainer>
      </FormikProvider>
    </>
  );
};
