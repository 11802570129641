import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  AMS_PORTAL_EXTERNAL_BFF,
  APIResource,
  Method,
  MethodTypes,
  queryApi,
  requestApi,
  requestExternalApi,
} from 'src/api';
import { QueryKey } from 'src/api/constants';

import {
  DocumentDetailsCodec,
  DocumentDownloadCodec,
  MatterDocumentsCodec,
  MatterTrimDocumentFolderCodec,
  MatterTrimDocumentsCodec,
  PresignedUrlCodec,
  RequestType,
  TrimUploadDocuments,
  UploadDocument,
} from './types';

const fetchDocumentDetails = (
  isCustomer: boolean,
  matterUId: string | null,
  documentId: string | null,
) => {
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.documents,
      suffix: ['matters', `${matterUId}`, 'documents', `${documentId}`],
    },
    DocumentDetailsCodec,
  );
};

export const useDocumentDetails = (
  isCustomer: boolean,
  matterUId: string | null,
  documentId: string | null,
) => {
  return useQuery(
    [QueryKey.MATTERS_DOCUMENT, matterUId, documentId],
    () => fetchDocumentDetails(isCustomer, matterUId, documentId),
    {
      enabled: !!matterUId && !!documentId,
    },
  );
};
const isFulfilled = <T>(
  result: PromiseSettledResult<T>,
): result is PromiseFulfilledResult<T> => result.status === 'fulfilled';

export const useDocumentsDetails = (
  isCustomer: boolean,
  matterUId: string | null,
  documentIds: string[],
) => {
  const queryKey = [
    QueryKey.MATTERS_DOCUMENTS,
    matterUId,
    documentIds.join('-'),
  ];

  const queryFn = async () => {
    const documentDetailsPromises = documentIds.map((documentId) =>
      fetchDocumentDetails(isCustomer, matterUId, documentId),
    );

    const documentDetailsResults = await Promise.allSettled(
      documentDetailsPromises,
    );

    // Filter for fulfilled results and safely access the value property
    const fulfilledDocuments = documentDetailsResults
      .filter(isFulfilled)
      .map((result) => result.value);

    return fulfilledDocuments;
  };

  return useQuery(queryKey, queryFn, {
    staleTime: 0,
    enabled: matterUId !== null && documentIds.length > 0,
  });
};

export const downloadDocumentUrl = (
  isCustomer: boolean,
  matterUId: string,
  documentId: string,
) => {
  const downloadDocumentUrlSuffix = isCustomer
    ? 'external-download-url'
    : 'internal-download-url';
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.documents,
      suffix: [
        'matters',
        `${matterUId}`,
        'documents',
        `${documentId}`,
        `${downloadDocumentUrlSuffix}`,
      ],
    },
    DocumentDownloadCodec,
  );
};

export const useDownloadDocument = () => {
  return {
    downloadDocumentUrl,
    downloadDocument: (requestUrl: string, fileName: string) =>
      requestExternalApi(
        requestUrl,
        'GET' as Method,
        undefined,
        'blob',
        fileName,
      ),
  };
};

export const downloadTrimDocument = (
  matterUId: string,
  documentId: string,
  trimRecordNumber: string,
) => {
  return requestApi(
    {
      resourcePath: APIResource.documents,
      suffix: [
        'matters',
        `${matterUId}`,
        'documents',
        `${documentId}`,
        `trim`,
        `download`,
      ],
      params: { trimRecordNumber },
    },
    'GET' as Method,
    undefined,
    undefined,
    'blob',
  );
};

export const useDownloadTrimDocument = () => {
  return {
    downloadTrimDocument,
  };
};

const fetchPresignedUrl = (
  matterUId: string,
  isCustomer: boolean,
  count: number = 1,
) => {
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.documents,
      suffix: [
        'matters',
        `${matterUId}`,
        'documents',
        isCustomer ? 'external-upload-urls' : 'internal-upload-urls',
      ],
      params: { count },
    },
    PresignedUrlCodec,
  );
};

const getPresignedUrl = (
  matterUId: string,
  isCustomer: boolean,
  count: number = 1,
) => fetchPresignedUrl(matterUId, isCustomer, count);

const uploadFileRequest = (requestUrl: string, file: File) =>
  requestExternalApi(requestUrl, 'PUT' as Method, file);

const uploadFile = (requestUrl: string, file: File) =>
  uploadFileRequest(requestUrl, file);

const uploadDocumentByMatterUIdRequest = (
  matterUId: string,
  uploadDocumentParams: UploadDocument[],
  isCustomer: boolean,
  requestType = RequestType.Add,
) => {
  return requestApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.documents,
      suffix: ['matters', `${matterUId}`, 'documents'],
    },
    requestType === RequestType.Add ? MethodTypes.POST : MethodTypes.PUT,
    uploadDocumentParams,
    undefined,
    undefined,
    // passing headers
    {
      'content-type': 'application/json',
    },
  );
};

const uploadDocumentByMatterUId = (
  matterUId: string,
  uploadDocumentParams: UploadDocument[],
  isCustomer: boolean,
  requestType = RequestType.Add,
) =>
  uploadDocumentByMatterUIdRequest(
    matterUId,
    uploadDocumentParams,
    isCustomer,
    requestType,
  );

// used only in send response to audit commencement documents

const updateCorrespondenceDocumentRequest = (
  matterUId: string,
  threadId: string,
  correspondenceId: string,
  uploadDocumentParams: UploadDocument[],
  isCustomer: boolean,
) => {
  return requestApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.documents,
      suffix: [
        'matters',
        `${matterUId}`,
        'threads',
        `${threadId}`,
        'correspondences',
        `${correspondenceId}`,
        'documents',
      ],
    },
    MethodTypes.PUT,
    uploadDocumentParams,
    undefined,
    undefined,
    // passing headers
    {
      'content-type': 'application/json',
    },
  );
};

const updateCorrespondenceDocument = (
  matterUId: string,
  threadId: string,
  correspondenceId: string,
  uploadDocumentParams: UploadDocument[],
  isCustomer: boolean,
) =>
  updateCorrespondenceDocumentRequest(
    matterUId,
    threadId,
    correspondenceId,
    uploadDocumentParams,
    isCustomer,
  );

export const useUploadDocument = () => {
  return {
    getPresignedUrl,
    uploadFile,
    uploadDocumentByMatterUId,
    updateCorrespondenceDocument,
  };
};

const fetchMatterDocuments = (
  portalMatterId: string,
  isCustomer: boolean = false,
) => {
  return queryApi(
    {
      resourcePath: APIResource.documents,
      suffix: ['matters', `${portalMatterId}`, 'documents', 'basic'],
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
    },
    MatterDocumentsCodec,
  );
};

export const useMatterDocuments = (
  portalMatterId: string | null,
  isCustomer: boolean = false,
) => {
  const shouldFetch = portalMatterId !== null;

  return useQuery(
    [QueryKey.MATTER_DOCUMENTS, portalMatterId],
    () => {
      if (shouldFetch) {
        return fetchMatterDocuments(portalMatterId, isCustomer);
      }
      return null;
    },
    {
      enabled: shouldFetch,
    },
  );
};

const fetchTrimFolder = (portalMatterId: string) => {
  return queryApi(
    {
      resourcePath: APIResource.documents,
      suffix: ['matters', `${portalMatterId}`, 'documents', 'trim', 'folder'],
    },
    MatterTrimDocumentFolderCodec,
  );
};

export const useTrimFolder = (
  portalMatterId: string | null,
  isCustomer: boolean = false,
) => {
  const shouldFetch = portalMatterId !== null;

  return useQuery(
    [QueryKey.MATTER_TRIM_DOCUMENTS_FOLDER, portalMatterId],
    () => {
      if (shouldFetch) {
        return fetchTrimFolder(portalMatterId);
      }
      return null;
    },
    {
      enabled: shouldFetch && !isCustomer,
    },
  );
};

const fetchMatterTrimDocuments = (portalMatterId: string) => {
  return queryApi(
    {
      resourcePath: APIResource.documents,
      suffix: ['matters', `${portalMatterId}`, 'documents'],
    },
    MatterTrimDocumentsCodec,
  );
};

export const useMatterTrimDocuments = (portalMatterId: string | null) => {
  const shouldFetch = portalMatterId !== null;

  return useQuery(
    [QueryKey.MATTER_TRIM_DOCUMENTS, portalMatterId],
    () => {
      if (shouldFetch) {
        return fetchMatterTrimDocuments(portalMatterId);
      }
      return null;
    },
    {
      enabled: shouldFetch,
    },
  );
};

const uploadDocToTrim = (
  docUploadRequest: TrimUploadDocuments,
  portalMatterId: string | null,
) => {
  return requestApi(
    {
      resourcePath: APIResource.documents,
      suffix: ['matters', `${portalMatterId}`, 'trim', 'upload'],
    },
    MethodTypes.POST,
    docUploadRequest,
    undefined,
    undefined,
    {
      'content-type': 'application/json',
    },
  );
};
export const useUploadDocToTrim = (
  portalMatterId: string | null,
  navigateSuccess: (
    data: {
      recordNumber: string;
      documentId: string;
      clientId: string;
      trimTitle: string;
      trimDescription: string;
    }[],
  ) => void,
  navigateError: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: TrimUploadDocuments) => {
      return uploadDocToTrim(payload, portalMatterId);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          QueryKey.MATTER_TRIM_DOCUMENTS,
          portalMatterId,
        ]);
        navigateSuccess((data as any).data);
      },
      onError: () => {
        console.log('error');
        navigateError();
      },
    },
  );
};
