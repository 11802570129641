import { useState } from 'react';
import {
  FormGroup,
  Heading,
  IconAdd,
  IconEdit,
  InPageAlert,
  Input,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '@snsw/react-component-library';
import { AccordionItem } from '@snsw/react-component-library/build/Components';
import { FileUpload, IconDelete, Tooltip } from 'ams-common';
import { FormikErrors } from 'formik';
import { ClientOptions } from 'src/components/clients/types';
import {
  InvalidFormatUploadFailure,
  SystemErrorUploadFailure,
} from 'src/components/fileUploadErrors';
import { EmptyFilesUploadErrorModal } from 'src/components/fileUploadErrors/EmptyFileUploadErrorModal';
import { VALID_FILE_FORMATS } from 'src/constants';
import { useUploadDocument } from 'src/hooks';
import {
  StyledIconCell,
  StyledTitle,
} from 'src/screens/common/matters/tabs/common/matterCorrespondence/styles';
import getContent, { combineContent } from 'src/utils/contentUtils';

import { CLASSIFICATION_SECTIONS } from '../constants';
import { DescriptionModal } from '../modals/descriptionModal';
import { SubmitResponseToAuditCommencementDocumentsFormValues } from '../types';

import { HiddenInputs } from './hiddenInputs';
import {
  NoStyledButton,
  StyledAccordion,
  StyledErrorText,
  StyledFileUploadContainer,
} from './styles';

interface CurrentFile {
  sectionId: string;
  fileId: string | null | undefined;
  description: string;
  isDescriptionMandatory: boolean;
}

interface ClassificationsSectionsProps {
  entities: ClientOptions;
  matterUId: string | null;
  isCustomer: boolean;
  values: SubmitResponseToAuditCommencementDocumentsFormValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: FormikErrors<SubmitResponseToAuditCommencementDocumentsFormValues>;
  submitCount: number;
  setIsFilesUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ClassificationsSections = ({
  entities,
  matterUId,
  isCustomer,
  values,
  setFieldValue,
  errors,
  submitCount,
  setIsFilesUploading,
}: ClassificationsSectionsProps) => {
  const [currentFile, setCurrentFile] = useState<CurrentFile | null>(null);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const { uploadFile, getPresignedUrl } = useUploadDocument();
  const [openInvalidFormatUploadFailure, setOpenInvalidFormatUploadFailure] =
    useState<boolean>(false);
  const [unsupportedFormatFiles, setUnsupportedFormatFiles] = useState<File[]>(
    [],
  );

  const [openEmptyFilesUploadErrorModal, setOpenEmptyFilesUploadErrorModal] =
    useState<boolean>(false);
  const [emptyFiles, setEmptyFiles] = useState<File[]>([]);
  const [filesSectionId, setFilesSectionId] = useState<string>();
  const [validFiles, setValidFiles] = useState<File[]>([]);
  const [filesWithApiError, setFilesWithApiError] = useState<File[]>([]);

  const uploadFilesToS3 = async (
    validFiles: File[] | null,
    sectionId?: string,
  ) => {
    if (validFiles && validFiles.length > 0 && matterUId && sectionId) {
      setIsFilesUploading(true);
      try {
        const filesArray = Array.from(validFiles);
        const filteredFiles = matterUId ? filesArray : [];

        // Keep track of the successfully uploaded files
        const presignedUrls = await getPresignedUrl(
          matterUId,
          isCustomer,
          filteredFiles.length,
        );

        const uploadedFiles = await Promise.all(
          presignedUrls.map(async ({ url, s3Key }, index) => {
            const file = filteredFiles[index];
            try {
              await uploadFile(url, file);
              return { file, s3Key };
            } catch (error) {
              setFilesWithApiError((prev) => [...prev, file]);
              return null;
            }
          }),
        );

        // Filter out any null values (failed uploads)
        const successfulUploads = uploadedFiles.filter(
          (upload) => upload !== null,
        ) as { file: File; s3Key: string }[];

        // Create the updated section files array with only uploaded files
        const updatedSectionFiles = successfulUploads.map(
          ({ file, s3Key }) => ({
            fileId: s3Key,
            fileName: file.name,
            fileSize: file.size,
            description: '',
            isUploaded: false,
          }),
        );

        // Update the section in the form with only the uploaded files
        setFieldValue('sections', {
          ...values.sections,
          [sectionId]: [
            ...((values.sections && values.sections[sectionId]) || []),
            ...updatedSectionFiles,
          ],
        });
      } catch (error) {
        console.error('File upload failed:', error);
      } finally {
        setIsFilesUploading(false);
        setFilesSectionId('');
      }
    }
  };

  const handleFileUpload = async (uploadedFiles: File[], sectionId: string) => {
    const { validFiles, invalidFiles, emptyFiles } = uploadedFiles.reduce(
      (acc, file) => {
        const isMsgExtension =
          file.type === '' ? file.name.endsWith('.msg') : false;
        if (VALID_FILE_FORMATS.includes(file.type) || isMsgExtension) {
          if (file.size === 0) {
            acc.emptyFiles.push(file);
          } else {
            acc.validFiles.push(file);
          }
        } else {
          acc.invalidFiles.push(file);
        }
        return acc;
      },
      {
        validFiles: [] as File[],
        invalidFiles: [] as File[],
        emptyFiles: [] as File[],
      },
    );

    if (invalidFiles.length > 0) {
      setOpenInvalidFormatUploadFailure(true);
      setUnsupportedFormatFiles(invalidFiles);
      setValidFiles(validFiles);
      setFilesSectionId(sectionId);
    } else if (emptyFiles.length > 0) {
      setOpenEmptyFilesUploadErrorModal(true);
      setEmptyFiles(emptyFiles);
      setValidFiles(validFiles);
      setFilesSectionId(sectionId);
    } else {
      setValidFiles(validFiles);
      uploadFilesToS3(validFiles, sectionId);
    }
  };

  const handleDescriptionChange = (
    description: string,
    fileId: string | null | undefined,
    sectionId: string,
  ) => {
    setFieldValue('sections', {
      ...values.sections,
      [sectionId]: ((values.sections && values.sections[sectionId]) || []).map(
        (file) => (file.fileId === fileId ? { ...file, description } : file),
      ),
    });
  };

  const handleEntityChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    fileId: string | null | undefined,
    sectionId: string,
  ) => {
    const { value } = event.target;
    setFieldValue('sections', {
      ...values.sections,
      [sectionId]: ((values.sections && values.sections[sectionId]) || []).map(
        (file) => (file.fileId === fileId ? { ...file, entity: value } : file),
      ),
    });
  };

  const handleOpenDescriptionModal = (
    fileId: string | null | undefined,
    sectionId: string,
    description: string,
    isDescriptionMandatory: boolean,
  ) => {
    setCurrentFile({ sectionId, fileId, description, isDescriptionMandatory });
    setOpenDescriptionModal(true);
  };

  const handleCloseDescriptionModal = () => {
    setCurrentFile(null);
    setOpenDescriptionModal(false);
  };

  const handleRemoveFile = (
    fileId: string | null | undefined,
    sectionId: string,
  ) => {
    const updatedSections = {
      ...values.sections,
      [sectionId]: (values.sections?.[sectionId] || []).filter(
        (file) => file.fileId !== fileId,
      ),
    };

    const nonEmptySections = Object.fromEntries(
      Object.entries(updatedSections).filter(
        ([, files]) => (files as any[]).length > 0,
      ),
    );

    setFieldValue('sections', nonEmptySections);
  };

  return (
    <>
      <Heading level={3}>
        {getContent(
          'matters.correspondence.submitResponseToAuditCommencementDocuments.uploadDocuments.heading',
        )}
      </Heading>
      {submitCount > 0 && values?.sections === null && errors.sections ? (
        <InPageAlert
          variant="error"
          title={getContent(
            'matters.correspondence.submitResponseToAuditCommencementDocuments.error.alert.title',
          )}
        >
          <p>
            {getContent(
              'matters.correspondence.submitResponseToAuditCommencementDocuments.error.alert.text',
            )}
          </p>
        </InPageAlert>
      ) : null}
      {isCustomer ? (
        <InPageAlert variant="info" title="" compact>
          <p>
            <strong>
              {getContent(
                'matters.correspondence.submitResponseToAuditCommencementDocuments.documents.authorised.contact.info',
              )}
            </strong>
          </p>
        </InPageAlert>
      ) : null}
      <Input
        name="sections"
        style={{ position: 'absolute', left: '-9999px' }}
      />
      <StyledAccordion
        id="section-accordions"
        name="Upload documents"
        title="Upload Documents"
      >
        {CLASSIFICATION_SECTIONS.map(({ sectionName, sectionId }) => {
          return (
            <AccordionItem
              name={errors.sections?.[sectionId]}
              key={sectionId}
              id={sectionId}
              title={
                <StyledTitle>
                  <Tooltip
                    text={getContent(
                      `matters.correspondence.submitResponseToAuditCommencementDocuments.${sectionId}.tooltip.text` as keyof typeof combineContent,
                    )}
                    label=""
                    noTranslateLeft
                  />
                  {sectionName}
                </StyledTitle>
              }
              expanded
            >
              <StyledFileUploadContainer>
                <FileUpload
                  onFileUpload={(uploadedFiles) => {
                    handleFileUpload(uploadedFiles, sectionId);
                  }}
                />
                {values.sections &&
                  values.sections[sectionId] &&
                  values.sections[sectionId].length > 0 && (
                    <TableContainer
                      title="Uploaded documents"
                      id="upload-documents"
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableHeader>Filename (Size)</TableHeader>
                            <TableHeader>
                              <StyledTitle>
                                Entity name
                                <Tooltip
                                  text="Please choose the organisation that the document should be attributed to."
                                  label=""
                                />
                              </StyledTitle>
                            </TableHeader>
                            <TableHeader>
                              <StyledTitle>
                                Description
                                <Tooltip
                                  text="Entering a description is optional. However, it is mandatory to enter a description for documents uploaded in the 'Other documents' category."
                                  label=""
                                />
                              </StyledTitle>
                            </TableHeader>
                            <TableHeader>
                              <StyledTitle>
                                Remove
                                <Tooltip
                                  text="Uploaded document(s) can be removed before the final submission."
                                  label=""
                                  alignLeft
                                />
                              </StyledTitle>
                            </TableHeader>
                          </TableRow>
                        </TableHead>

                        <HiddenInputs sectionId={sectionId} index="entity" />
                        <HiddenInputs
                          sectionId={sectionId}
                          index="description"
                        />
                        <HiddenInputs sectionId={sectionId} index="fileName" />
                        <TableBody>
                          {values.sections[sectionId].map(
                            (
                              {
                                fileName,
                                fileSize,
                                description,
                                fileId,
                                entity,
                              },
                              index,
                            ) => {
                              return (
                                <TableRow key={`${sectionId}-${fileId}`}>
                                  <TableCell>
                                    <Input
                                      name={`sections-${sectionId}-${index}-fileName`}
                                      style={{
                                        position: 'absolute',
                                        left: '-9999px',
                                      }}
                                    />
                                    <span
                                      style={
                                        errors.sections?.[sectionId]?.[index]
                                          ?.fileName
                                          ? {
                                              border: '2px solid #b81237',
                                              padding: '8px',
                                            }
                                          : {}
                                      }
                                    >
                                      {fileName}{' '}
                                      {`(${(fileSize / (1024 * 1024)).toFixed(
                                        2,
                                      )}MB)`}
                                    </span>
                                  </TableCell>
                                  <TableCell className="entity">
                                    <FormGroup
                                      name={`sections-${sectionId}-${index}-entity`}
                                      id="entity"
                                      hasError={
                                        errors.sections?.[sectionId]?.[index]
                                          ?.entity ?? false
                                      }
                                      errorMessage={
                                        errors.sections?.[sectionId]?.[index]
                                          ?.entity || ''
                                      }
                                    >
                                      <Select
                                        name={`sections-${sectionId}-${index}-entity`}
                                        value={entity}
                                        options={entities}
                                        onChange={(e) =>
                                          handleEntityChange(
                                            e,
                                            fileId,
                                            sectionId,
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </TableCell>
                                  <TableCell>
                                    <NoStyledButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDescriptionModal(
                                          fileId,
                                          sectionId,
                                          description,
                                          sectionId === 'other-documents',
                                        );
                                      }}
                                      hasError={
                                        errors.sections?.[sectionId]?.[index]
                                          ?.description ?? false
                                      }
                                      name={`sections-${sectionId}-${index}-description`}
                                    >
                                      <StyledIconCell>
                                        {description ? (
                                          <IconEdit />
                                        ) : (
                                          <IconAdd />
                                        )}
                                        <span>Description</span>
                                      </StyledIconCell>
                                    </NoStyledButton>
                                    <StyledErrorText>
                                      {errors.sections?.[sectionId]?.[index]
                                        ?.description || ''}
                                    </StyledErrorText>
                                  </TableCell>
                                  <TableCell>
                                    <NoStyledButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveFile(fileId, sectionId);
                                      }}
                                    >
                                      <StyledIconCell>
                                        <IconDelete />
                                        <span>Remove</span>
                                      </StyledIconCell>
                                    </NoStyledButton>
                                  </TableCell>
                                </TableRow>
                              );
                            },
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
              </StyledFileUploadContainer>
            </AccordionItem>
          );
        })}
      </StyledAccordion>
      {openDescriptionModal && currentFile && (
        <DescriptionModal
          description={currentFile.description}
          setDescription={(description) => {
            if (currentFile) {
              handleDescriptionChange(
                description,
                currentFile.fileId,
                currentFile.sectionId,
              );
            }
          }}
          closeModal={handleCloseDescriptionModal}
          isDescriptionMandatory={currentFile.isDescriptionMandatory}
        />
      )}
      <InvalidFormatUploadFailure
        open={openInvalidFormatUploadFailure}
        close={() => {
          setOpenInvalidFormatUploadFailure(false);
          setUnsupportedFormatFiles([]);
        }}
        onContinue={() => {
          setOpenInvalidFormatUploadFailure(false);
          setUnsupportedFormatFiles([]);
          uploadFilesToS3(validFiles, filesSectionId);
        }}
        invalidFiles={unsupportedFormatFiles || []}
        validFiles={validFiles || []}
      />
      <EmptyFilesUploadErrorModal
        open={openEmptyFilesUploadErrorModal}
        close={() => {
          setOpenEmptyFilesUploadErrorModal(false);
          setEmptyFiles([]);
        }}
        onContinue={() => {
          setOpenEmptyFilesUploadErrorModal(false);
          setEmptyFiles([]);
          uploadFilesToS3(validFiles, filesSectionId);
        }}
        emptyFiles={emptyFiles || []}
        validFiles={validFiles || []}
      />
      <SystemErrorUploadFailure
        open={filesWithApiError.length > 0}
        close={() => setFilesWithApiError([])}
        type={
          filesWithApiError.length === validFiles.length
            ? 'complete'
            : 'partial'
        }
      />
    </>
  );
};
