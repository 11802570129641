import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Col,
  ComponentLoader,
  ContentContainer,
  FormGroup,
  Row,
} from '@snsw/react-component-library';
import { Breadcrumbs, ErrorSummary, formatDate } from 'ams-common';
import { useFormik } from 'formik';
import { QueryKey } from 'src/api/constants';
import { useClients } from 'src/components/clients/hooks';
import { DocumentsTable } from 'src/components/correspondence/documentsTable';
import { DocumentUploadContainer } from 'src/components/correspondence/documentUploadContainer';
import { EntityNames } from 'src/components/correspondence/entityNames';
import { Message } from 'src/components/correspondence/message';
import { MessageSubject } from 'src/components/correspondence/messageSubject';
import { Recipient } from 'src/components/correspondence/recipient';
import DeleteDocument from 'src/components/deleteDocument';
import DocumentModal from 'src/components/documentModal';
import { useMatterDocuments } from 'src/components/documentTableWithAccordion/hooks';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { InvalidFormatUploadFailure } from 'src/components/fileUploadErrors';
import { ScreenNames } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';
import {
  useAuditorDetails,
  useDocumentDetails,
  usePortalContactsSummary,
  useRecipients,
  useSystemDateTime,
  useUserContext,
} from 'src/hooks';
import {
  useCorrespondenceDetails,
  useCreateUpdateCorrespondence,
  useMatterCorrespondenceResponses,
  useMatterThreadDetails,
} from 'src/hooks/correspondence/hooks';
import {
  useDocumentsDetails,
  useDownloadDocument,
} from 'src/hooks/document/hooks';
import { getSelectedRecipientData } from 'src/hooks/recipients/helpers';
import { RequestType } from 'src/hooks/types';
import { PATHS } from 'src/routes/constants';
import { SubNavigationTitle } from 'src/screens/common/matters/types';
import { getMattersBreadcrumbs } from 'src/screens/common/matters/utils';
import { UserLoginType } from 'src/types';
import { OperationType } from 'src/types/documentTypes';
import getContent, { combineContent } from 'src/utils/contentUtils';
import { downloadSelectedDocument } from 'src/utils/documentHelper';
import { getValidInvalidFilesArray } from 'src/utils/fileUtils';

import { CORRESPONDENCE_TYPE_MAPPER, MessageType } from '../constants';
import {
  getContentKeyFromCorrespondenceKey,
  getCorrespondenceTitleFromCorrespondenceKey,
} from '../helper';

import { Confirm } from './modals/confirm';
import { SaveAsDraft } from './modals/saveAsDraft';
import { ActionButtons } from './actionButtons';
import { RFI_DEFAULT_DUE_DATE } from './constants';
import { DueDate } from './dueDate';
import {
  getClientIdsFromResponse,
  getEntityNames,
  getInitValuesFromResponse,
  getRecipientNames,
  getRecipientOptions,
  getRecipientsIdsFromResponse,
  getRequestBody,
  getUploadedDocumentsDetailsFromResponse,
} from './helpers';
import {
  StyledErrorContainer,
  StyledHeading,
  StyledMessageContainer,
} from './styles';
import { CorrespondenceInitValues, EditDocument, ModalType } from './types';
import {
  auditorValidationSchema,
  customerValidationSchema,
} from './validation';

const initialValues: CorrespondenceInitValues = {
  entities: '',
  recipient: '',
  dueDate: '',
  messageSubject: '',
  message: '',
  documents: [],
};

export const Correspondence = () => {
  const { correspondenceId: draftCorrespondenceId = '' } = useParams();
  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;
  const {
    matterUId,
    matterId,
    matterCorrespondenceThreadId: threadId,
  } = useMatter();
  const {
    data: auditorDetails,
    isLoading: isAuditorDetailsLoading,
    isError: isAuditorDetailsError,
  } = useAuditorDetails(isCustomer);

  const {
    clientOptions,
    clients,
    isError: isClientDataError,
  } = useClients(matterUId, isCustomer);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [operationType, setOperationType] = useState<OperationType>(
    OperationType.Add,
  );
  const [files, setFiles] = useState<File[] | null>();
  const [invalidFiles, setInvalidFiles] = useState<File[] | null>(null);
  const [openFilesUploadErrorModal, setOpenFilesUploadErrorModal] =
    useState<boolean>(false);
  const [selectedDocumentToEdit, setSelectedDocumentToEdit] =
    useState<string>('');
  const [selectedDocumentIdToRemove, setSelectedDocumentIdToRemove] =
    useState<string>('');
  const [selectedDocumentNameToRemove, setSelectedDocumentNameToRemove] =
    useState<string>('');
  const [editDocumentDetails, setEditDocumentDetails] =
    useState<EditDocument | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaveAsDraftModalOpen, setIsSaveAsDraftModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<string[]>([]);
  const [corroType, setCorroType] = useState('');
  const [refetchDocumentDetailsToggle, setRefetchDocumentDetailsToggle] =
    useState(false);
  const [
    isAtLeastOneResponseFromCustomerSubmitted,
    setIsAtLeastOneResponseFromCustomerSubmitted,
  ] = useState(false);
  const [isAtLeastOneResponseSubmitted, setIsAtLeastOneResponseSubmitted] =
    useState(false);
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const { state } = useLocation() || {};

  const errorSummaryRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { data: systemDateTime, isLoading: isSystemDateTimeLoading } =
    useSystemDateTime(isCustomer);
  const {
    data: documentsData,
    isLoading: matterDocumentsLoading,
    isError: isDocumentsDataError,
  } = useMatterDocuments(matterUId, isCustomer);
  const {
    data: documentDetailsToEdit,
    refetch: documentsDetailToEditRefetch,
    isLoading: isDocumentDetailsToEditLoading,
    isError: isDocumentsDetailsError,
  } = useDocumentDetails(isCustomer, matterUId, selectedDocumentToEdit);

  const {
    data: draftCorrespondenceDetails,
    isLoading: isDraftCorrespondenceDetailsLoading,
    isError: isDraftCorrespondenceDetailsError,
  } = useCorrespondenceDetails(
    matterUId,
    threadId,
    draftCorrespondenceId,
    isCustomer,
  );

  const {
    data: uploadedDocumentsDetails,
    refetch: documentDetailsRefetch,
    isLoading: isDocumentDetailsLoading,
    isError: isUploadedDocumentsDetailsError,
  } = useDocumentsDetails(isCustomer, matterUId, uploadedDocuments);

  const { downloadDocumentUrl, downloadDocument } = useDownloadDocument();

  const {
    data: correspondenceResponses,
    isLoading: isCorrespondenceResponsesLoading,
  } = useMatterCorrespondenceResponses(isCustomer, matterUId, threadId);

  const {
    data: threadDetails,
    isLoading: isThreadDetailsLoading,
    isError: isThreadDetailsFetchingError,
  } = useMatterThreadDetails(isCustomer, matterUId, threadId);

  const portalContactIds = useMemo(
    () => threadDetails?.portalContactIds ?? [],
    [threadDetails],
  );

  const {
    data: portalContactsSummary,
    isLoading: isPortalContactsSummaryLoading,
  } = usePortalContactsSummary(isCustomer, portalContactIds);

  const navigateSuccess = (statusCode: number, saveAs: 'draft' | undefined) => {
    if (statusCode === 201) {
      navigate(`${PATHS.matters}/${matterId}${PATHS.threads}`, {
        state: {
          messageType: MessageType.Success,
          message:
            saveAs === 'draft'
              ? 'Draft saved successfully'
              : 'Correspondence sent successfully',
        },
      });
    }
  };

  const navigateError = (data: {
    status: number;
    code: string;
    messages?: string[];
  }) => {
    if (data?.messages?.[0].includes('proper date')) {
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CORRESPONDENCE_FORM}-DUE_DATE-POST` as ErrorKey,
        ]),
      ]);
    } else {
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTERS_CORRESPONDENCES}-POST` as ErrorKey,
        ]),
      ]);
    }
  };

  const {
    mutate: createUpdateCorrespondence,
    isLoading: isCreateUpdateCorrespondenceLoading,
  } = useCreateUpdateCorrespondence(navigateSuccess, navigateError);

  const {
    values,
    submitCount,
    errors,
    setValues,
    isSubmitting,
    isValid,
    setFieldValue,
    handleSubmit,
    setSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: isCustomer
      ? customerValidationSchema()
      : auditorValidationSchema(
          new Date(),
          corroType,
          isAtLeastOneResponseSubmitted,
        ),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      setIsConfirmModalOpen(true);
    },
  });

  const {
    data: recipientsData,
    isLoading: isRecipientDataLoading,
    isError: isRecipientDataError,
  } = useRecipients(matterUId, isCustomer);

  useEffect(() => {
    if (isRecipientDataError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.RECIPIENT_CONTACTS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isRecipientDataError]);

  useEffect(() => {
    if (isAuditorDetailsError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTER_CONTACTS_AUDITOR_DETAILS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isAuditorDetailsError]);

  useEffect(() => {
    if (isDocumentsDataError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTER_DOCUMENTS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isDocumentsDataError]);

  useEffect(() => {
    if (isClientDataError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.CLIENTS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isClientDataError]);

  useEffect(() => {
    if (isDocumentsDetailsError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTERS_DOCUMENT}-GET` as ErrorKey,
        ]),
      ]);
  }, [isDocumentsDetailsError]);

  useEffect(() => {
    if (isUploadedDocumentsDetailsError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTERS_DOCUMENTS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isUploadedDocumentsDetailsError]);

  useEffect(() => {
    if (isDraftCorrespondenceDetailsError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.CORRESPONDENCE_DETAILS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isDraftCorrespondenceDetailsError]);

  useEffect(() => {
    if (isThreadDetailsFetchingError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTERS_CORRESPONDENCES}-GET` as ErrorKey,
        ]),
      ]);
  }, [isThreadDetailsFetchingError]);

  useEffect(() => {
    if (!clients) {
      return;
    }
    const leadClientId = clients.find(({ leadClient }) => leadClient)?.id;

    if (!leadClientId) {
      return;
    }

    setFieldValue('entities', `${leadClientId}`);
  }, [clients, setFieldValue]);

  const recipientOptions = useMemo(() => {
    const selectedEntity =
      values.entities ||
      (clientOptions?.length === 1 ? clientOptions[0].value : '');
    const selectedRecipientData = getSelectedRecipientData(
      selectedEntity,
      recipientsData,
    );

    return getRecipientOptions(selectedRecipientData);
  }, [clientOptions, recipientsData, values.entities]);

  useEffect(() => {
    if (!isValid && submitCount > 0) {
      errorSummaryRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isValid, submitCount]);

  useEffect(() => {
    if (errorKeys.length > 0) {
      errorSummaryRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errorKeys]);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedDocumentToEdit) {
        return;
      }
      await documentsDetailToEditRefetch();
    };
    fetchData();
  }, [
    documentsDetailToEditRefetch,
    selectedDocumentToEdit,
    refetchDocumentDetailsToggle,
  ]);

  useEffect(() => {
    if (!documentDetailsToEdit) {
      return;
    }

    const { name, id, clients, classification, description } =
      documentDetailsToEdit;

    setEditDocumentDetails({
      documentName: name,
      documentId: id,
      clientId: clients.map(({ id }) => id).join(','),
      classification,
      description,
    });
  }, [documentDetailsToEdit, refetchDocumentDetailsToggle]);

  useEffect(() => {
    if (!draftCorrespondenceDetails) {
      return;
    }
    const initValuesFromResponse = getInitValuesFromResponse(
      draftCorrespondenceDetails,
      isCustomer,
    );

    setValues({
      ...initValuesFromResponse,
    });
  }, [draftCorrespondenceDetails, isCustomer, setValues]);

  useEffect(() => {
    const fetchData = async () => {
      if (!draftCorrespondenceDetails) {
        return;
      }

      const { documents } = draftCorrespondenceDetails;

      if (!documents) {
        return;
      }

      const documentsFromResponse = documents.map(({ id }) => id);

      setUploadedDocuments(documentsFromResponse);
      await documentDetailsRefetch();
    };
    fetchData();
  }, [draftCorrespondenceDetails, documentDetailsRefetch]);

  useEffect(() => {
    if (!uploadedDocumentsDetails) {
      return;
    }
    const uploadedDocuments = getUploadedDocumentsDetailsFromResponse(
      uploadedDocumentsDetails,
    );
    setFieldValue('documents', uploadedDocuments);
  }, [setFieldValue, uploadedDocumentsDetails]);

  const setFieldFromDetails = useCallback(
    (field: string, value?: string | null) => {
      if (!value || draftCorrespondenceId) return;
      setFieldValue(field, value);
    },
    [draftCorrespondenceId, setFieldValue],
  );

  useEffect(() => {
    setFieldFromDetails('messageSubject', threadDetails?.lastSubject);
  }, [threadDetails?.lastSubject, setFieldFromDetails]);

  useEffect(() => {
    setFieldFromDetails('dueDate', threadDetails?.dueDate);
  }, [threadDetails?.dueDate, setFieldFromDetails]);

  useEffect(() => {
    if (!threadDetails?.portalContactIds || draftCorrespondenceId) return;
    const contactIdsStr = getRecipientsIdsFromResponse(
      threadDetails?.portalContactIds,
    );
    setFieldValue(
      'recipient',
      isCustomer ? auditorDetails?.fullName : contactIdsStr,
    );
  }, [
    auditorDetails?.fullName,
    draftCorrespondenceId,
    isCustomer,
    setFieldValue,
    threadDetails?.portalContactIds,
  ]);

  useEffect(() => {
    if (!threadDetails?.portalContactIds || draftCorrespondenceId) return;

    const entities = getClientIdsFromResponse(portalContactsSummary);

    setFieldValue('entities', entities);
  }, [
    draftCorrespondenceId,
    portalContactsSummary,
    setFieldValue,
    threadDetails?.portalContactIds,
  ]);

  useEffect(() => {
    if (threadDetails) {
      const { corroType } = threadDetails;
      setCorroType(corroType ?? '');
    } else if (state) {
      const { action } = state;
      setCorroType(action);
      if (action === 'REQUEST FOR INFORMATION' && systemDateTime) {
        const date =
          new Date(systemDateTime).getTime() +
          RFI_DEFAULT_DUE_DATE * 24 * 60 * 60 * 1000;
        const defaultDate = new Date(date);
        setFieldValue(
          'dueDate',
          formatDate(defaultDate.toDateString(), 'YYYY-MM-DD'),
        );
      }
    }
  }, [threadDetails, setFieldValue, state, systemDateTime]);

  useEffect(() => {
    if (!correspondenceResponses) {
      return;
    }
    const responseFromCustomerSubmitted = correspondenceResponses.some(
      (response) => response.response && response.replyFrom === 'CUSTOMER',
    );
    const responseSubmitted = correspondenceResponses.some(
      (response) => response.sentDate,
    );
    setIsAtLeastOneResponseFromCustomerSubmitted(responseFromCustomerSubmitted);
    setIsAtLeastOneResponseSubmitted(responseSubmitted);
  }, [correspondenceResponses]);

  const handleFieldChange = ({
    value,
    field,
  }: {
    value: string;
    field: string;
  }) => {
    setValues({
      ...values,
      ...{ [field]: value },
    });
  };

  const handleFileUpload = async (uploadedFiles: File[]) => {
    const { validFiles, invalidFiles } =
      getValidInvalidFilesArray(uploadedFiles);

    if (invalidFiles.length > 0) {
      setOpenFilesUploadErrorModal(true);
    } else if (invalidFiles.length === 0) {
      setOpenDocumentModal(true);
    }

    setInvalidFiles(invalidFiles);
    setFiles(validFiles);
  };

  const handleCloseDocumentModal = () => {
    setOpenDocumentModal(false);
    setEditDocumentDetails(null);
    setOperationType(OperationType.Add);
  };

  const handleFileUploadValue = ({
    fileName,
    docId,
    fileSize,
  }: {
    fileName: string;
    docId: string | null;
    fileSize: number;
  }) => {
    if (!docId) {
      return;
    }
    setFieldValue('documents', [
      ...(values.documents ?? []),
      ...[
        {
          documentId: docId,
          documentName: fileName,
          documentSize: fileSize,
        },
      ],
    ]);
  };

  const handleDocumentEdit = ({ documentId }: { documentId: string }) => {
    setSelectedDocumentToEdit(documentId);
    setOperationType(OperationType.Edit);
    setOpenDocumentModal(true);
    setRefetchDocumentDetailsToggle((prev) => !prev);
  };

  const handleRemoveDocument = ({
    documentId,
    documentName,
  }: {
    documentId: string;
    documentName: string;
  }) => {
    setIsDeleteModalOpen(true);
    setSelectedDocumentIdToRemove(documentId);
    setSelectedDocumentNameToRemove(documentName);
  };

  const handleCloseDeleteDocumentModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleAfterDocumentDelete = (documentId?: string) => {
    setFieldValue('documents', [
      ...(values.documents ?? []).filter(
        ({ documentId: docId }) => docId !== documentId,
      ),
    ]);
  };

  const submitForm = async (saveAs?: 'draft') => {
    try {
      const requestBody = getRequestBody(
        values,
        corroType,
        ModalType.Draft,
        isCustomer,
      );

      if (matterUId) {
        const isReplay = draftCorrespondenceId
          ? false
          : (isCustomer && isAtLeastOneResponseFromCustomerSubmitted) ||
            (!isCustomer && isAtLeastOneResponseSubmitted);

        const requestType = draftCorrespondenceId
          ? RequestType.Update
          : RequestType.Add;

        await createUpdateCorrespondence([
          matterUId,
          requestBody,
          requestType,
          threadId || undefined,
          draftCorrespondenceId || undefined,
          saveAs,
          isCustomer,
          isReplay,
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveAsDraft = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setIsSaveAsDraftModalOpen(false);
    submitForm('draft');
  };

  const handleConfirm = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setIsConfirmModalOpen(false);
    await submitForm();
  };

  const handleBack = () =>
    navigate(`${PATHS.matters}/${matterId}${PATHS.threads}`);

  const handleDownloadDocument = async (
    documentId: string,
    documentName: string,
  ) => {
    try {
      if (matterUId) {
        const { url: DownloadUrl } = await downloadDocumentUrl(
          false,
          matterUId,
          documentId,
        );
        const response = await downloadDocument(DownloadUrl, documentName);
        downloadSelectedDocument(response, documentName);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error message:', error.message);
      } else if (typeof error === 'string') {
        // This is a custom error message
        console.error('Custom error message:', error);
      } else {
        // Handle other error types
        console.error('Unknown error type');
      }
    }
  };

  const contentKey = getContentKeyFromCorrespondenceKey(corroType);

  const subNavigationTitle = `threads.${contentKey}` as SubNavigationTitle;

  return (
    <ContentContainer>
      <ComponentLoader
        active={
          matterDocumentsLoading ||
          isDocumentDetailsToEditLoading ||
          isDraftCorrespondenceDetailsLoading ||
          isDocumentDetailsLoading ||
          isCorrespondenceResponsesLoading ||
          isRecipientDataLoading ||
          isSystemDateTimeLoading ||
          isCreateUpdateCorrespondenceLoading ||
          isAuditorDetailsLoading ||
          isThreadDetailsLoading ||
          isPortalContactsSummaryLoading
        }
        fullPage
      />
      <Breadcrumbs
        paths={getMattersBreadcrumbs('threads', subNavigationTitle, matterId)}
        isAccessible={!isCustomer}
      />

      <Row>
        <Col span={8}>
          <StyledHeading level={2}>
            {getContent(
              `matter.correspondence.${contentKey}.${
                isCustomer ? 'customer' : 'auditor'
              }.heading` as keyof typeof combineContent,
            )}
          </StyledHeading>
          <StyledMessageContainer>
            <span>{getContent('matter.correspondence.message')}</span>
          </StyledMessageContainer>
        </Col>
      </Row>
      <StyledErrorContainer
        ref={errorSummaryRef}
        showError={errorKeys.length > 0 || (!isValid && submitCount > 0)}
      />
      <ErrorSummary errors={errors} />
      <ErrorHandler keys={errorKeys} />

      <form>
        <Row>
          <Col span={8}>
            <EntityNames
              isCustomer={isCustomer}
              clientOptions={clientOptions}
              value={values.entities}
              hasError={!!(submitCount > 0 && errors.entities)}
              errorMessage={errors.entities}
              handleChange={handleFieldChange}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Recipient
              isCustomer={isCustomer}
              recipientOptions={recipientOptions || []}
              hasError={!!(submitCount > 0 && errors.recipient)}
              errorMessage={errors.recipient}
              value={values.recipient}
              handleChange={handleFieldChange}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <DueDate
              isCustomer={isCustomer}
              value={values.dueDate}
              hasError={!!(submitCount > 0 && errors.dueDate)}
              errorMessage={errors.dueDate}
              handleChange={handleFieldChange}
              isAtLeastOneResponseSubmitted={isAtLeastOneResponseSubmitted}
              threadId={threadId}
              matterUId={matterUId}
              corroType={corroType}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <MessageSubject
              hasError={!!(submitCount > 0 && errors.messageSubject)}
              errorMessage={errors.messageSubject}
              value={values.messageSubject}
              handleChange={handleFieldChange}
              placeholder={
                getCorrespondenceTitleFromCorrespondenceKey(corroType) || ''
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Message
              value={values.message}
              errorMessage={errors.message}
              hasError={!!(submitCount > 0 && errors.message)}
              handleChange={handleFieldChange}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DocumentUploadContainer
              handleFileUpload={handleFileUpload}
              isCustomer={isCustomer}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormGroup
              name="selectedDocuments"
              hasError={!!(submitCount > 0 && errors.documents)}
              errorMessage={errors.documents}
            >
              <DocumentsTable
                handleDocumentDelete={handleRemoveDocument}
                uploadedDocuments={values.documents}
                handleDocumentEdit={handleDocumentEdit}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <ActionButtons
              handleSubmit={handleSubmit}
              handleSaveAsDraft={() => {
                setIsSaveAsDraftModalOpen(true);
              }}
              handleBack={handleBack}
              disable={isSubmitting}
              isAtLeastOneResponseFromCustomerSubmitted={
                isAtLeastOneResponseFromCustomerSubmitted
              }
              isAtLeastOneResponseSubmitted={isAtLeastOneResponseSubmitted}
              isCustomer={isCustomer}
              draftCorrespondenceId={draftCorrespondenceId}
            />
          </Col>
        </Row>
      </form>
      {openDocumentModal && !isDocumentDetailsToEditLoading && (
        <DocumentModal
          matterUId={matterUId}
          close={handleCloseDocumentModal}
          handleFileUploadValue={handleFileUploadValue}
          documents={files || null}
          clientOptions={clientOptions}
          matterId={matterId}
          documentsData={documentsData}
          operationType={operationType}
          editDocumentDetails={editDocumentDetails}
          selectedCustomer={values.entities}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteDocument
          matterUId={matterUId}
          documentId={selectedDocumentIdToRemove}
          documentName={selectedDocumentNameToRemove}
          close={handleCloseDeleteDocumentModal}
          isOpen={isDeleteModalOpen}
          handleAfterDocumentDelete={handleAfterDocumentDelete}
          isCustomer={isCustomer}
        />
      )}
      <InvalidFormatUploadFailure
        open={openFilesUploadErrorModal}
        close={() => setOpenFilesUploadErrorModal(false)}
        onContinue={() => {
          if (files && files?.length > 0) {
            setOpenDocumentModal(true);
          } else {
            setOpenFilesUploadErrorModal(false);
          }
        }}
        invalidFiles={invalidFiles || []}
        validFiles={files || []}
      />
      <SaveAsDraft
        open={isSaveAsDraftModalOpen}
        close={() => {
          setIsSaveAsDraftModalOpen(false);
        }}
        isCustomer={isCustomer}
        handleSaveAsDraft={handleSaveAsDraft}
        corroType={CORRESPONDENCE_TYPE_MAPPER[corroType]}
      />
      <Confirm
        open={isConfirmModalOpen}
        close={() => {
          setSubmitting(false);
          setIsConfirmModalOpen(false);
        }}
        isCustomer={isCustomer}
        handleSubmit={handleConfirm}
        entityNames={getEntityNames(values, clients)}
        recipientNames={getRecipientNames(values, recipientsData)}
        dueDate={values.dueDate}
        message={values.message}
        messageSubject={values.messageSubject}
        documents={values.documents}
        handleDownloadDocument={handleDownloadDocument}
        initiatedBy={auditorDetails?.fullName}
        contentKey={contentKey}
        hasAuditorOrCustomerResponse={
          isCustomer
            ? isAtLeastOneResponseFromCustomerSubmitted
            : isAtLeastOneResponseSubmitted
        }
      />
    </ContentContainer>
  );
};
