import { ClassificationOptions } from './types';

export const SESSION_STORAGE_CUSTOMER_ACCESS_TOKEN = 'access_token';
export const SESSION_STORAGE_KEY_USER_INFO = 'USER_INFO';

export const CLASSIFICATION_OPTIONS_AUDITOR: ClassificationOptions[] = [
  {
    apiSectionName: 'Audit Questionnaire',
    text: 'Audit Questionnaire',
    value: 'audit questionnaire',
    sectionId: 'audit-questionnaire',
  },
  {
    apiSectionName: 'Audit Scoping Template',
    text: 'Audit Scoping Template',
    value: 'audit scoping template',
    sectionId: 'audit-scoping-template',
  },
  {
    apiSectionName: 'Compliance Initiation Letter (UFC)',
    text: 'Compliance Initiation Letter (UFC)',
    value: 'compliance initiation letter (ufc)',
    sectionId: 'compliance-initiation-letter-ufc',
  },
  {
    apiSectionName: 'Field Audit Questionnaire',
    text: 'Field Audit Questionnaire',
    value: 'field audit questionnaire',
    sectionId: 'field-audit-questionnaire',
  },
  {
    apiSectionName: 'Finalisation Advice',
    text: 'Finalisation advice',
    value: 'finalisation advice',
    sectionId: 'finalisation-advice',
  },
  {
    apiSectionName: 'Instalment Plan',
    text: 'Instalment Plan',
    value: 'instalment plan',
    sectionId: 'instalment-plan',
  },
  {
    apiSectionName: 'Notice of Assessment',
    text: 'Notice of Assessment',
    value: 'notice of assessment',
    sectionId: 'notice-of-assessment',
  },
  {
    apiSectionName: 'Notice of Investigation (NOI)',
    text: 'Notice of Investigation (NOI)',
    value: 'notice of investigation (noi)',
    sectionId: 'notice-of-investigation-noi',
  },
  {
    apiSectionName: 'Preliminary Findings',
    text: 'Preliminary Findings',
    value: 'preliminary findings',
    sectionId: 'preliminary-findings',
  },
  {
    apiSectionName: 'Request for Information',
    text: 'Request for Information',
    value: 'request for information',
    sectionId: 'request-for-information',
  },
  {
    apiSectionName: 's72 Notices',
    text: 's72 Notices',
    value: 's72 notices',
    sectionId: 's72-notices',
  },
  {
    apiSectionName: 'Other',
    text: 'Other',
    value: 'other',
    sectionId: 'other-documents',
  },
];

export const CLASSIFICATION_OPTIONS_CUSTOMER: ClassificationOptions[] = [
  {
    apiSectionName: 'Apprentices and Trainees Information',
    text: 'Apprentices & Trainees Information',
    value: 'apprentices & trainees information',
    sectionId: 'apprentices-trainees-information',
  },
  {
    apiSectionName: 'Audit Questionnaire',
    text: 'Audit Questionnaire',
    value: 'audit questionnaire',
    sectionId: 'audit-questionnaire',
  },
  {
    apiSectionName: 'Audit Scoping Template',
    text: 'Audit Scoping Template',
    value: 'audit scoping template',
    sectionId: 'audit-scoping-template',
  },
  {
    apiSectionName: 'Business or Group Structure',
    text: 'Business/Group Structure',
    value: 'business/group structure',
    sectionId: 'business-group-structure',
  },
  {
    apiSectionName: 'Contractor Information',
    text: 'Contractor Information',
    value: 'contractor information',
    sectionId: 'contractor-information',
  },
  {
    apiSectionName: 'Employment Agency or Labour Hire Information',
    text: 'Employment Agency/Labour Hire Information',
    value: 'employment agency/labour hire information',
    sectionId: 'employment-agency-labour-hire-information',
  },
  {
    apiSectionName: 'Exempt Payment or Wages',
    text: 'Exempt Payment/Wages',
    value: 'exempt payment/wages',
    sectionId: 'exempt-payment-wages',
  },
  {
    apiSectionName: 'Financial Statements',
    text: 'Financial statements',
    value: 'financial statements',
    sectionId: 'financial-statements',
  },
  {
    apiSectionName: 'Fringe Benefits Information',
    text: 'Fringe Benefits Information',
    value: 'fringe benefits information',
    sectionId: 'fringe-benefits-information',
  },
  {
    apiSectionName: 'Instalment Plan',
    text: 'Instalment Plan',
    value: 'instalment plan',
    sectionId: 'instalment-plan',
  },
  {
    apiSectionName: 'Intention for Objection',
    text: 'Intention for Objection',
    value: 'intention for objection',
    sectionId: 'intention-for-objection',
  },
  {
    apiSectionName: 'Jobkeeper Payment',
    text: 'Jobkeeper payments ',
    value: 'jobkeeper payments ',
    sectionId: 'jobkeeper-payments',
  },
  {
    apiSectionName: 'Other Financial Reports',
    text: 'Other Financial Reports',
    value: 'other financial reports',
    sectionId: 'other-financial-reports',
  },
  {
    apiSectionName: 'Payments Outside the Payroll System',
    text: 'Payments outside the payroll system',
    value: 'payments outside the payroll system',
    sectionId: 'payments-outside-payroll-system',
  },
  {
    apiSectionName: 'Payroll Records',
    text: 'Payroll Records',
    value: 'payroll records',
    sectionId: 'payroll-records',
  },
  {
    apiSectionName: 'Payroll Tax Working Papers',
    text: 'Payroll Tax Working Papers',
    value: 'payroll tax working papers',
    sectionId: 'payroll-tax-working-papers',
  },
  {
    apiSectionName: 'Shares and Options Scheme',
    text: 'Shares & Options Scheme',
    value: 'shares & options scheme',
    sectionId: 'shares-options-scheme',
  },
  {
    apiSectionName: 'Superannuation Report',
    text: 'Superannuation Report',
    value: 'superannuation report',
    sectionId: 'superannuation-report',
  },
  {
    apiSectionName: 'Taxable Payments Annual Report',
    text: 'Taxable payments annual report',
    value: 'taxable payments annual Report',
    sectionId: 'taxable-payments-annual-report',
  },
  {
    apiSectionName: 'Trust Deed',
    text: 'Trust deed',
    value: 'trust deed',
    sectionId: 'trust-deed',
  },
  {
    apiSectionName: 'Other',
    text: 'Other',
    value: 'other',
    sectionId: 'other-documents',
  },
];

export enum ScreenNames {
  DASHBOARD = 'DASHBOARD',
  MATTERS = 'MATTERS',
  CUSTOMER_LOGIN = 'CUSTOMER_LOGIN',
  CUSTOMER_ONBOARDING = 'CUSTOMER_ONBOARDING',
  AUDITOR_OVERVIEW = 'AUDITOR_OVERVIEW',
  CUSTOMER_OVERVIEW = 'CUSTOMER_OVERVIEW',
  CORRESPONDENCE = 'CORRESPONDENCE',
  CORRESPONDENCE_DETAILS = 'CORRESPONDENCE_DETAILS',
  CORRESPONDENCE_FORM = 'CORRESPONDENCE_FORM',
  AUDITOR_CONTACTS = 'AUDITOR_CONTACTS',
  CUSTOMER_CONTACTS = 'CUSTOMER_CONTACTS',
  STATUS_MANAGER = 'STATUS_MANAGER',
  MESSAGES = 'MESSAGES',
  MESSAGE_DETAILS = 'MESSAGE_DETAILS',
  MESSAGE_SEND = 'MESSAGE_SEND',
  MATTER_NOTIFICATIONS = 'MATTER_NOTIFICATIONS',
  CUSTOMER_MATTER_DOCUMENTS = 'CUSTOMER_MATTER_DOCUMENTS',
  AUDITOR_MATTER_DOCUMENTS = 'AUDITOR_MATTER_DOCUMENTS',
  MATTER_DOCUMENTS_TRIM = 'MATTER_DOCUMENTS_TRIM',
  MATTER_DOCUMENTS = 'MATTER_DOCUMENTS',
  SUBMIT_AUDIT_CORRESPONDENCE = 'SUBMIT_AUDIT_CORRESPONDENCE',
}

export const VALID_FILE_FORMATS = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/gif',
  'image/jpeg',
  'application/pdf',
  'image/png',
  'image/tiff',
  'text/plain',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-outlook',
  'message/rfc822',
];
