import { useEffect, useRef } from 'react';
import { Modal } from '@snsw/react-component-library';
import environments from 'src/environments';

const Captcha = ({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: (wafToken: string) => void;
}) => {
  const captchaContainer = useRef(null);

  useEffect(() => {
    if (captchaContainer.current) {
      window.AwsWafCaptcha.renderCaptcha(captchaContainer.current, {
        apiKey: environments.captchaApiKey,
        onSuccess: (wafToken: string) => {
          console.log('Captcha submit with new wafToken:', wafToken);
          onSubmit(wafToken);
        },
        onError: (error: any) => {
          console.error('Captcha error', error);
        },
      });
    }
  }, [captchaContainer, onSubmit, onClose]);

  return (
    <Modal title="" onClose={onClose} buttons={[]}>
      <div ref={captchaContainer} />
    </Modal>
  );
};

export default Captcha;
