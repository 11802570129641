declare global {
  interface Window {
    AwsWafCaptcha: {
      renderCaptcha: (el: HTMLDivElement, config: any) => void;
    };
    AwsWafIntegration: {
      hasToken: () => boolean;
      getToken: () => Promise<string>;
    };
  }
}

// The AwsWafIntegration and AwsWafCaptcha are loaded form 'jsapi.js', but seem to be created
// asynchronously and at different times, so wait here until both objects are available.
const isAwsWafLoaded = (): boolean =>
  !!(window.AwsWafIntegration && window.AwsWafCaptcha);

const LOADING_DELAY = 200;
const LOADING_TIMEOUT_SECONDS = 3;

export const waitToLoadAwsWaf = (): Promise<boolean> =>
  new Promise((resolve) => {
    if (isAwsWafLoaded()) {
      resolve(true);
      return;
    }

    let attempts = (LOADING_TIMEOUT_SECONDS * 1000) / LOADING_DELAY;

    const interval = setInterval(() => {
      if (isAwsWafLoaded()) {
        clearInterval(interval);
        resolve(true);
      }
      // eslint-disable-next-line no-plusplus
      if (attempts-- <= 0) {
        console.error(
          `Failed to load AwsWaf objects: AwsWafIntegration and AwsWafCaptcha after ${LOADING_TIMEOUT_SECONDS} seconds`,
        );
        clearInterval(interval);
        resolve(false);
      }
    }, LOADING_DELAY);
  });

export const hasWafToken = (): boolean =>
  !!window.AwsWafIntegration?.hasToken();

export const getWafToken = async (): Promise<string> => {
  await waitToLoadAwsWaf();
  return window.AwsWafIntegration.getToken();
};
